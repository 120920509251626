App.Modules = App.Modules || {};
App.Modules.FormCollapse = function () {
   var o = {};

   var openNext = function(data) {
      var clicked = $(data.eventElement);
      var nextStep = parseInt(clicked.data('step'));

      nextStep += 1;
      $('.js-collapse[data-step='+nextStep+']').slideDown(250);
   };
   return {
      init: function() {
         $('.js-collapse:gt(0)').hide();
         return this;
      },
      events: function() {
         Events.bind("click", ".js-donate-trigger-step").to(openNext, {context: this});
         return this;
      }
   };
}();

