App.Modules = App.Modules || {};
App.Modules.ButtonGroup = function () {
   var o = {};

   var findClickedButton = function(data) {
      $('.js-button.clicked').removeClass('clicked js-selected-button');
      $(data.eventElement).addClass('clicked js-selected-button');

   };

   return {
      init: function() {
         return this;
      },
      events: function() {
         Events.bind("click", ".js-button").to(findClickedButton, {context: this});
         return this;
      }
   };
}();

