App.Modules = App.Modules || {};
App.Modules.Modal = function () {

   var o = { };

   // this was supposed to be a general purpose modal but because of a change to content in an iframe this had to be moved
   // this is only for the take action donor search
   var displayModal = function(data) {
      $.featherlight(
        '<div style="padding: 20px; text-align: center; font-size: 150%;">' +
          '<p><b>The impact of your gift to Embrace Kids Foundation may be doubled or possibly tripled!</b></p>' +
          '<p>To find out if your company will match your donation to Embrace Kids Foundation, please contact your human resources department or you can look up your company\'s matching gift policy here:</p>' +
          '<iframe class="js-modal-target" src="https://www1.matchinggifts.com/embracekids_iframe/" width="100%" height="250" scrolling="auto" border="0" style="border: none; background: white; display: block;"></iframe>' +
          '</div>',
        {
          
        }
      );

      return false;
   };

   return {
      init: function() {
        return this;
      },
      events: function() {
         Events.bind("click", ".js-modal").to(displayModal, {context: this});
         return this;
      }
   };
}();