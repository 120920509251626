App.Modules = App.Modules || {};
App.Modules.Fader = function () {

   var o = {
      delay: 8000,
      fadeTime: 750,
      interval: null
   };

   var fadeSlider = function() {
      o.interval = setInterval(function() {
         var slide = $('.js-fader > .js-slide:first');

         slide.fadeOut(o.fadeTime, function() {
            var next = $(this).next();
            toNextSlide(next, true);
         });
      }, o.delay);

   };

   var pickSlide = function(data) {
      var clicked = $(data.eventElement);
      var slide = $('.js-fader > .js-slide:first');
      clearInterval(o.interval);

      slide.fadeOut(o.fadeTime, function() {
         var next = $(".js-slide[data-slide='"+clicked.data('slide')+"']");
         toNextSlide(next);
      });
   };

   var tansitionDot = function(next) {
      $('.js-slider-dot.current').removeClass('current');
      $("a[data-slide='"+next.data('slide')+"']").addClass('current');
   };

   var toNextSlide = function(next, append) {
      tansitionDot(next);
      next
          .fadeIn(350)
          .end();

      if (append) {
         next.prev()
             .appendTo('.js-fader');
      } else {
         next.prependTo('.js-fader');
      }
   };

   return {
      init: function() {
         if ($(".js-fader > .js-slide").length > 1) {
            $('.js-fader > .js-slide:gt(0)').hide();
            fadeSlider();
         } else {
            $('.js-slider-position').hide();
         }

         return this;
      },
      events: function() {
         Events.bind("click", ".js-slider-dot").to(pickSlide, {context: this});

         return this;
      }
   };
}();

