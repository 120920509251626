App.Modules = App.Modules || {};
App.Modules.Popup = function () {

   var o = { };

   var displayPopup = function(data) {
      var $link = $(data.eventElement).attr('href');
      var $title = $(data.eventElement).data('title');
      var newWindow = window.open($link, $title,'height=480,width=640');

      if (window.focus) {
         newWindow.focus();
      }

      return false;
   };

   return {
      init: function() { return this; },
      events: function() {
         Events.bind("click", ".js-popup").to(displayPopup, {context: this});
         return this;
      }
   };
}();

