App.Modules = App.Modules || {};
App.Modules.MobileNavigation = function () {
   var o = {
      navTypeOn: 'fa-bars',
      navTypeOff: 'fa-close'
   };

   var toggleNav = function(data) {
      var dataAttr = data.eventElement.getAttribute("data-nav");
      if (dataAttr === "show") {
         $('.js-mobile-nav').fadeIn(250, function() {
            $('body').css('overflow', 'hidden');
         });
      } else {
         $('.js-mobile-nav').fadeOut(250, function() {
            $('body').css('overflow', '');
         });
      }
   };

   return {
      init: function() { return this; },
      events: function() {
         Events.bind("click", ".js-toggle-navigation").to(toggleNav, {context: this});

         return this;
      }
   };
}();
