App.Modules = App.Modules || {};
App.Modules.Expand = function () {

   var o = { };

   var expandTarget = function(data) {
      var targetId = $(data.eventElement).data('expand'),
      $target = $('#' + targetId),
      $header = $('header.site-header'),
      headerHeight = $header.height(),
      scrolledY,
      magicNumber = 90;

      $target.show();
      
      // scroll to the target
      $target[0].scrollIntoView(true);
      scrolledY = window.scrollY;
      if (scrolledY)
      {
          window.scroll(0, scrollY - headerHeight - magicNumber);
      }


      return false;
   };

   return {
      init: function() { return this; },
      events: function() {
         Events.bind("click", ".js-expand").to(expandTarget, {context: this});
         return this;
      }
   };
}();