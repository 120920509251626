App.Modules = App.Modules || {};
App.Modules.StickyHeader = function () {
   var o = { };

   var displayDropshadow = function(data) {
      if ($(window).scrollTop() > 100) {
         $(".js-header").css("box-shadow", "0px 3px 5px 0px rgba(67,67,67, 0.45)");
      } else {
         $(".js-header").css("box-shadow", "none");
      }
   };

   return {
      init: function() { return this; },
      events: function() {
         Events.bind("scroll").to(displayDropshadow, {context: window});

         return this;
      }
   };
}();

