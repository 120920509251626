App.Modules = App.Modules || {};
App.Modules.Slider = function () {

   var o = {
      position: 0,
   };

   var slideClick = function(data) {
      var $el        = $(data.eventElement);
      var slideBy    = $el.data('increment') == 1 ? "-100vw" : "100vw";
      var positionCheck = $el.data('increment') == 1 ? 100 : -100;
      var slideCount = ($(".js-slide").length - 1)  * 100;

      o.position += positionCheck;

      if (o.position >= 0 && o.position <= slideCount) {
         $('.js-slide').animate({
            left: "+="+slideBy
         }, 750);
      } else {
         o.position -= positionCheck;
      }
   };

   return {
      init: function() {
         var $slides = $(".js-slide");

         if ($slides.length <= 1) {
            $(".js-slide-toggle").hide();
         }

         $slides.each(function(index) {
            $(this).css("left", index * 100 + "vw");
         });


         return this;
      },
      events: function() {
         Events.bind("click", '.js-slide-button').to(slideClick, {context: this, prevent: true});
         return this;
      }
   };
}();

