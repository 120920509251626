App.Helpers = App.Helpers || {};

App.Helpers.fadeIn = function(prop, display) {
   var el = document.querySelector(prop);
   el.style.opacity = 0;
   el.style.display = display || "block";

   (function fade() {
      var val = parseFloat(el.style.opacity);
      if (!((val += .1) > 1)) {
         el.style.opacity = val;
         requestAnimationFrame(fade);
      }
   })();
};

App.Helpers.fadeOut = function(prop) {
   var el = document.querySelector(prop);
   el.style.opacity = 1;

   (function fade() {
      if ((el.style.opacity -= .1) < 0) {
         el.style.display = "none";
      } else {
         requestAnimationFrame(fade);
      }
   })();
};
