App.Modules = App.Modules || {};
App.Modules.VideoPlayer = function () {
   var o = {
      navTypeOn: 'fa-bars',
      navTypeOff: 'fa-close'
   };

   var toggleNav = function(data) {
      var dataAttr = data.eventElement.getAttribute("data-nav");
      if (dataAttr === "show") {
         $('.js-mobile-nav').fadeIn(250, function() {
            $('body').css('overflow', 'hidden');
         });
      } else {
         $('.js-mobile-nav').fadeOut(250, function() {
            $('body').css('overflow', '');
         });
      }
   };

   var openVideo = function(data) {
      var $video = $(data.eventElement).data('video');
      var $title = $(data.eventElement).data('title');

      if ($video != "" && $video != " " && $video != "#") {

         $('body').css('overflow', 'hidden');
         $('.js-video-player').fadeIn(250);

         $(".js-video-embed").attr('src', $video.replace("https://youtu.be/","https://www.youtube.com/embed/"));
         $(".js-video-title").html($title);
      }
   };

   var closeVideo = function(data) {
      console.log("close");
      $('.js-video-player').fadeOut(250, function() {
         $(this).find('iframe').attr('src', '');
         $('body').css('overflow', '');
      });
   };


   return {
      init: function() { return this; },
      events: function() {
         Events.bind('click', '.js-play-video').to(openVideo, {context: this});

         Events.bind('click', '.js-video-close').to(closeVideo, {context: this});
         Events.bind('click', '.js-video-player').to(closeVideo, {context: this});
         Events.bind('keydown', [27]).to(closeVideo, {context: window});

         return this;
      }
   };
}();
